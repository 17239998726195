.arrow-layout-wrap {
  display: flex;
  align-items: center;
  .arrow-layout {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .anticon {
      width: 10px;
      height: 8px;
      color: #c9cdd4;
      &.active {
        color: #da2428;
      }
    }
  }
}

@primary-color: #DA2428;@link-color: #DA2428;