.comment-search-list-wrap {
  background: #f7f7f7;
  padding: 16px 20px;
  .input-box {
    height: 141px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .list-input-wrap {
    width: 1065px;
  }
  .list-input {
    input {
      height: 50px;
    }
  }
}

@primary-color: #DA2428;@link-color: #DA2428;