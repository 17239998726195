.header {
  position: relative;
  display: flex;
  height: 56px;
  align-items: center;
  text-align: center;
  color: white;
  background: #da2428;
  font-size: 24px;
  h2 {
    height: 100%;
    line-height: 56px;
    font-size: 26px;
    color: #fff;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .left-area {
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    .menu-wrap {
      display: flex;
      margin-left: 23px;
      .menu-item {
        padding: 4px 12px;
        font-size: 14px;
        line-height: 22px;
        cursor: pointer;
        a {
          text-decoration: none;
          color: #ffffff;
        }
        &.active {
          background: #e55558;
          border-radius: 8px;
        }
      }
      .menu-item + .menu-item {
        margin-left: 12px;
      }
    }
  }
}

@primary-color: #DA2428;@link-color: #DA2428;