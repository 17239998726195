.page-search {
  padding-top: 50px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 960px;
  min-height: calc(100vh - 100px);
  margin: 0 auto;
  .clamp-1 {
    -webkit-line-clamp: 1;
    overflow: hidden;
    max-width: 580px;
  }
  .ant-list-empty-text {
    display: none;
  }
  .panel {
    border-radius: 4px;
    background: white;
    padding: 0 15px 15px;
    width: 800px;
    &.video-info {
      video {
        margin: 0 auto;
        display: block;
        width: 480px;
      }
    }
    &.search-bar {
      padding: 15px;
    }
    .tip {
      margin: 5px 0;
      display: block;
      width: 100%;
      text-align: center;
    }
    .image-info-bar {
      display: flex;
      align-items: center;
      .icon-image {
        margin-right: 5px;
        font-size: 16px;
        transform: translateY(-2px);
      }
    }
    &.audio-info {
      .audio-player-bar {
        display: flex;
        justify-content: space-between;
        .icon-audio-download {
          width: 24px;
          font-size: 24px;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    .no-resource-tip {
      color: #666;
      text-align: center;
    }
    .swiper {
      .swiper-slide {
        max-height: 300px;
        img {
          max-height: 300px;
        }
      }
    }
  }
  &_input {
    height: 60px;
    border: 4px solid #333;
    font-size: 16px;
    font-weight: 600;
    font-family: fantasy;
  }
  &_btn {
    height: 60px !important;
    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .ant-list-item {
    strong {
      display: block;
      width: 100px;
    }
    a {
      color: lighten(#da2428, 10%);
    }
    svg {
      color: lighten(#da2428, 10%);
    }
  }
  h2 {
    width: 100%;
  }
  .ant-image-preview-footer {
    margin: 0 auto;
    border: 1px solid white;
    height: 50px;
    width: 400px;
  }
}
.popoverContainer {
  max-width: 300px;
  max-height: 480px;
  img {
    width: 100%;
  }
}

@primary-color: #DA2428;@link-color: #DA2428;