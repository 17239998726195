.filter-box {
  background-color: #fff;
  padding: 20px 30px;
  margin-top: 10px;
  .title {
    color: #1d2129;
    font-size: 16px;
    line-height: 28px;
    font-weight: bold;
  }
  .filter-form {
    display: flex;
  }
  .filter-item {
    display: flex;
    align-items: center;
    margin-right: 36px;
    .ant-input,
    .ant-picker {
      border: none !important;
      background-color: #f2f3f5 !important;
    }
  }
  .filter-label {
    margin-right: 10px;
    white-space: nowrap;
  }
  .ant-select-selector {
    border: none !important;
    background-color: #f2f3f5 !important;
  }
}

.table-box {
  background-color: #fff;
  padding: 20px 30px;
  margin-top: 10px;
  .select-box {
    .top-box {
      display: flex;
      align-items: center;
      .check-all {
        margin-right: 30px;
      }
      .select-wrap {
        display: flex;
        .select-item {
          padding: 4px 28px;
          font-size: 12px;
          line-height: 22px;
          border: 1px solid #e5e6eb;
          position: relative;
          display: flex;
          cursor: pointer;
          user-select: none;
          &.active {
            border: 1px solid #db4a48;
            color: #db4a48;
          }
        }
        .select-item:nth-child(1) {
          border-radius: 4px 0px 0px 4px;
        }
        .select-item:nth-child(2) {
          border-left: none;
          &.active::before {
            content: "";
            width: 1px;
            height: 100%;
            background: #db4a48;
            position: absolute;
            left: -1px;
            top: 0;
          }
        }
        .select-item:nth-child(3) {
          border-radius: 0px 4px 4px 0px;
          border-left: none;
          &.active::before {
            content: "";
            width: 1px;
            height: 100%;
            background: #db4a48;
            position: absolute;
            left: -1px;
            top: 0;
          }
        }
      }
    }
    .bottom-box {
      display: flex;
      margin-top: 18px;
      .label {
        color: #303033;
        font-size: 14px;
        margin-right: 30px;
        line-height: 25px;
      }
      .platform-wrap {
        display: flex;
        .platform-icon {
          width: 16px;
          height: 16px;
          margin-right: 3px;
        }
        .platform {
          padding: 4px 9px;
          color: #6b6767;
          font-size: 13px;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          align-items: center;
          &.active {
            background: linear-gradient(135deg, #ffb199 0%, #ff0844 100%);
            color: #ffffff;
          }
        }
        .platform + .platform {
          margin-left: 30px;
        }
      }
    }
  }
  .table-list {
    .list-item {
      padding: 20px 0;
      .list-item-top {
        .avatar-wrap {
          display: flex;
        }
        .avatar-box {
          display: flex;
        }
      }
      .avatar-box {
        font-size: 14px;
        .user-info {
          margin-left: 16px;
        }
        .nickname {
          color: #1d2129;
        }
        .location {
          color: #4e5969;
          display: flex;
          margin-top: 2px;
        }
        .divider {
          margin: 0 20px;
          margin-top: 4px;
          width: 1px;
          height: 14px;
          border-left: 1px dashed #e7e7e7;
        }
      }
      .content-box {
        margin-top: 10px;
        color: #000000;
        display: flex;
        align-items: baseline;
        .content {
          font-size: 16px;
          line-height: 30px;
          padding-left: 70px;
          word-break: break-all;
        }
        .copy-text {
          color: #da2428;
          font-size: 12px;
          margin-left: 10px;
          width: 80px;
          cursor: pointer;
        }
      }
      .sorce-info {
        background: #f2f3f5;
        padding: 10px;
        color: #86909c;
        font-size: 12px;
        line-height: 22px;
        margin-left: 65px;
        margin-top: 10px;
        .check-source {
          width: 48px;
        }
        .check-source a {
          color: #da2428;
          cursor: pointer;
          text-decoration: none;
        }
        .source-content {
          display: flex;
          justify-content: space-between;
        }
        .author-info {
          display: flex;
          margin-top: 12px;
          div + div {
            margin-left: 20px;
          }
        }
      }
      .publish-info {
        display: flex;
        color: #909099;
        font-size: 12px;
        line-height: 14px;
        margin-top: 14px;
        padding-left: 65px;
        justify-content: space-between;
        .publish-time {
          margin-left: 20px;
        }
        .left-box {
          display: flex;
        }
        .icon-num {
          margin-left: 4px;
        }
      }
    }
  }
}

.table-pagination {
  display: flex;
  justify-content: flex-end;
}

.empty-data {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-checkbox-wrapper {
  opacity: 0;
}

@primary-color: #DA2428;@link-color: #DA2428;