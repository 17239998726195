.search-type {
  display: flex;
  .search-type-item {
    color: #4e5969;
    font-size: 14px;
    line-height: 22px;
    padding: 5px 16px;
    background: rgba(242, 243, 245, 0.79);
    border-radius: 100px;
    cursor: pointer;
    &.active {
      background: linear-gradient(135deg, #ffb199 0%, #ff0844 100%);
      color: #ffffff;
    }
  }
  .search-type-item + .search-type-item {
    margin-left: 15px;
  }
}
.search-comment-input {
  margin-top: 18px;
  position: relative;
  .search-button {
    width: 35px;
    height: 35px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(135deg, #ffb199 0%, #ff0844 100%);
    box-shadow: 0px 2px 8px 0px rgba(246, 13, 70, 0.3);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 17px;
      height: 17px;
    }
  }
  input {
    height: 70px;
    border-radius: 18px;
    border: 3px solid rgba(255, 177, 153, 1);
    padding-right: 60px;
  }
}

@primary-color: #DA2428;@link-color: #DA2428;