.search-comment-container {
  background-color: #fff;
  .inner-wrap {
    width: 1065px;
    margin: 0 auto;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    .search-form {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      &.hasArea {
        .form-item {
          width: 250px;
        }
      }
      .form-item {
        width: 331px;
        height: 38px;
        background: #f5f6f7;
        border: none;
        .ant-select-selector {
          border: none;
          background: #f5f6f7;
        }
      }
    }
    .platform-wrap {
      display: flex;
      margin-top: 20px;
      .platform + .platform {
        margin-left: 15px;
      }
      .platform {
        color: #4e5969;
        font-size: 14px;
        line-height: 22px;
        padding: 5px 16px;
        background: rgba(242, 243, 245, 0.79);
        border-radius: 100px;
        cursor: pointer;
        &.active {
          background: linear-gradient(135deg, #ffb199 0%, #ff0844 100%);
          color: #ffffff;
        }
      }
    }
  }
}

@primary-color: #DA2428;@link-color: #DA2428;